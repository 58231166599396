<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader> Patient List </CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<CInput
									label="Nama Produk / Kode"
									placeholder="Enter Nama Produk / Kode"
									v-model="moreParams.filter"
								/>
							</CCol>
							<CCol md="3">
								<div role="group" class="form-group">
									<label for="status" class=""> Status </label>
									<select
										v-model="moreParams.status"
										id="status"
										class="form-control"
									>
										<option :value="null">pilih status</option>
										<option
											:key="a"
											v-for="a in status"
											:data-key="a"
											:value="a"
										>
											{{ a }}
										</option>
									</select>
								</div>
							</CCol>
						</CRow>
						<CRow>
							<div class="col col-md-12 table-responsive">
								<div
									v-show="!loadingTable"
									class="table-wrapper-scroll-y my-custom-scrollbar"
								>
									<table class="table table-sm table-hover">
										<thead>
											<tr>
												<th scope="col">#</th>
												<th scope="col">Product</th>
												<th scope="col">Pembelian</th>
												<th scope="col">Order</th>
												<th scope="col">Pembeli</th>
											</tr>
										</thead>
										<tbody>
											<tr :key="i" v-for="(od, i) in orders">
												<td>{{ i + 1 }}</td>
												<td>
													{{ od.namaProduct }}<br />
													{{ od.kodeProductItem }}<br />
													{{ od.size }} / {{ size[od.size].nama }} /
													{{ size[od.size].alias }}
												</td>
												<td>
													harga satuan : {{ od.hargaSatuan }} <br />
													jumlah : {{ od.jumlah }} <br />
													total : {{ od.totalBayar }}
												</td>
												<td>
													marketplace : {{ od.asal.toUpperCase() }}<br />
													kurier : {{ od.kurier.toUpperCase() }}<br />
													resi : {{ od.resi }}<br />
													kode order : {{ od.kodeOrder }}
												</td>
												<td>
													{{ od.tujuanNama }} / {{ od.tujuanHp }}<br />
													{{ od.tujuanAlamat }}<br />
													STATUS :
													<CBadge
														:color="colorBadge(od.status)"
														class="mfs-auto"
														>{{ od.status.toUpperCase() }}</CBadge
													>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-show="loadingTable" class="text-center">
									<ellipsis-loader :color="'#54f1d2'" />
								</div>
							</div>
						</CRow>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { debounce, size } from "@/plugins/utils";
export default {
	data() {
		return {
			moreParams: { status: null, filter: null, t_: Date.now() },
			loadingTable: false,
			orders: [],
			status: [
				"ready",
				"packing",
				"kirim",
				"cancel",
				"return",
				"selesai",
				"kirim ulang",
			],
			size,
		};
	},
	methods: {
		colorBadge(st) {
			if (st == "ready") return "info";
			if (st == "packing") return "warning";
			if (st == "kirim") return "success";
			if (st == "cancel") return "danger";
			if (st == "return") return "danger";
			return "danger";
		},
		async getData() {
			this.loadingTable = true;

			try {
				this.orders = [];
				const { data } = await this.$http.get("report/searchOrderByProduct", {
					params: this.moreParams,
				});

				// console.log(data.data);
				if (data.status == "success") {
					const d = data.data;
					for (let i = 0; i < d.length; i++) {
						const el = d[i];
						for (let j = 0; j < el.orderDetails.length; j++) {
							const e = el.orderDetails[j];
							const dd = {
								// order
								asal: el.asal,
								// harga:el.harga ,
								kodeOrder: el.kode,
								kurier: el.kurier,
								ongkir: el.ongkir,
								resi: el.resi,
								status: el.status,
								// total:el.total ,
								tujuanAlamat: el.tujuanAlamat,
								tujuanHp: el.tujuanHp,
								tujuanNama: el.tujuanNama,
								// order detail
								hargaBeliSatuan: e.hargaBeliSatuan,
								hargaSatuan: e.hargaSatuan,
								hargaTotal: e.hargaTotal,
								jumlah: e.jumlah,
								totalBayar: e.totalBayar,
								// product Item
								kodeProductItem: e.productItem.kode,
								size: e.productItem.size,
								//product
								kodeProduct: e.productItem.product.kode,
								namaProduct: e.productItem.product.nama,
							};
							this.orders.push(dd);
						}
					}
					//this.orders = data.data.data;
				} else {
					this.$swal("Error !", data.message, "error");
				}

				// console.log(datar);
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
			this.loadingTable = false;
		},
	},
	watch: {
		"moreParams.filter": debounce(function (val) {
			if (val.length > 3 || val.length == 0) this.getData();
		}, 1000),
		"moreParams.status": debounce(function (val) {
			this.getData();
		}, 1000),
	},
};
</script>
